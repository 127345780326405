<mat-toolbar>
  @if (isMobile()) {
    <button mat-icon-button
      class="menu-button"
      type="button"
      aria-label="Toggle sidenav"
      (click)="toggleDrawer.emit()"
      matTooltip="{{'menu.ShowMenu' | translate}}"
      >
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
  }

  <div class="flex flex-row gap-2 flex-auto items-center">
    @if (service.title()) {
      <h1 class="text-xl font-medium">{{ service.title() | translate }}</h1>
    }
    @if (service.searchVisible) {
      <app-main-search class="basis-1/4 flex flex-col flex-grow-0"></app-main-search>
    }
    <div class="flex-auto"></div>
    <app-main-header-actions class="basis-1/4"></app-main-header-actions>
    <app-activity-button class="flex flex-col content-stretch"></app-activity-button>
    <app-main-header-account class="flex flex-col content-stretch"></app-main-header-account>
  </div>
</mat-toolbar>
