import {Injectable} from '@angular/core'
import {MatIconRegistry} from '@angular/material/icon'
import {DomSanitizer} from '@angular/platform-browser'
import {ICON_CATEGORIES} from "./icon-categories"

@Injectable({
  providedIn: 'root'
})
export class IconService {
  constructor(private iconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
  }

  registerIcons(): void {
    const icons = Object.values(ICON_CATEGORIES).flat()

    icons.forEach((icon) => {
      this.iconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      )
    })
  }
}
