import { Component } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Meta } from '@angular/platform-browser'
import { RouterModule } from '@angular/router'
import { NgxGoogleAnalyticsModule } from '@hakimio/ngx-google-analytics'
import { AppService, AuthService, Company, CompanyService, ConsentService } from '@mybitzer-workspace/shared'
import { TranslateService } from '@ngx-translate/core'
import { Settings } from 'luxon'
import { Observable } from 'rxjs'
import { HomeComponent } from '../core/home/home/home.component'
import { environment } from '../environments/environment'
import { IconService } from './icon.service'
import { LoadingDialogComponent } from './loading-dialog.component'

@Component({
  imports: [RouterModule, HomeComponent, NgxGoogleAnalyticsModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'mybitzer-frontend'

  isHandset$: Observable<boolean>

  lang = this.translate.getBrowserLang()
  embedded = true
  company: Company | undefined
  protected readonly AuthService = AuthService
  private dialogRef: MatDialogRef<LoadingDialogComponent> | undefined

  constructor(
    private appService: AppService,
    public authService: AuthService,
    private translate: TranslateService,
    private companyService: CompanyService,
    private consentService: ConsentService,
    private dialog: MatDialog,
    private meta: Meta,
    private iconService: IconService
  ) {
    this.isHandset$ = this.appService.isMobile$
    this.consentService.init()
    this.iconService.registerIcons()
    this.initLang()

    this.translate.onLangChange.pipe(takeUntilDestroyed()).subscribe(evt => {
      this.meta.updateTag({ name: "language", content: evt.lang })
    })

    this.embedded = environment.embedded ?? false
  }

  initLang() {
    this.lang = this.translate.getBrowserLang()
    const availableLanguages = environment.availableLanguages.map(lang => lang.code)
    this.translate.addLangs(availableLanguages)
    if (this.lang && availableLanguages.includes(this.lang)) {
      this.translate.setDefaultLang('en')
      this.translate.use(this.lang)
      Settings.defaultLocale = this.lang
    } else {
      this.translate.setDefaultLang('en')
      this.translate.use('en')
      Settings.defaultLocale = 'en'
    }
  }
}
