import { FullscreenOverlayContainer, OverlayContainer } from "@angular/cdk/overlay"
import { registerLocaleData } from "@angular/common"
import { provideHttpClient, withInterceptors } from "@angular/common/http"
import localeDe from '@angular/common/locales/de'
import localeDeExtra from '@angular/common/locales/extra/de'
import { ApplicationConfig, LOCALE_ID, provideZoneChangeDetection } from '@angular/core'
import { MAT_DATE_LOCALE, provideNativeDateAdapter } from "@angular/material/core"
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field"
import { MatPaginatorIntl } from "@angular/material/paginator"
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async"
import { provideRouter, withComponentInputBinding, withInMemoryScrolling, withRouterConfig } from '@angular/router'
import { provideGoogleAnalytics } from '@hakimio/ngx-google-analytics'
import { MenuService } from "@mybitzer-workspace/backend"
import { ENVIRONMENT, MatPaginatorI18nService, MENU_SERVICE } from "@mybitzer-workspace/shared"
import { includeBearerTokenInterceptor } from "keycloak-angular"
import { environment } from '../environments/environment'
import { ROUTES } from './app.routes'
import { provideEchartsConfig } from "./echarts.config"
import { provideToastConfig } from "./hot-toast.config"
import { provideKeycloakAngular } from "./keycloak.config"
import { provideServiceConfig } from "./service.config"
import { provideTranslateConfig } from "./translate.config"

registerLocaleData(localeDe, 'de-DE', localeDeExtra)

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    { provide: ENVIRONMENT, useValue: environment },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    provideNativeDateAdapter(),
    provideToastConfig(),
    provideEchartsConfig(),
    provideServiceConfig(),
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    provideKeycloakAngular(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(withInterceptors([includeBearerTokenInterceptor])),
    provideTranslateConfig(),
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    },
    provideRouter(
      ROUTES,
      withComponentInputBinding(),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
        paramsInheritanceStrategy: 'always'
      }),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
      })
    ),
    provideGoogleAnalytics(environment.googleAnalyticsId),
    { provide: MENU_SERVICE, useClass: MenuService }
  ],
}
