import { Component } from '@angular/core'
import { MatIcon } from '@angular/material/icon'
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu'
import { RouterLink } from '@angular/router'
import { AuthService } from '@mybitzer-workspace/shared'
import { TranslatePipe } from '@ngx-translate/core'

@Component({
  selector: 'app-main-header-account',
  templateUrl: './main-header-account.component.html',
  styleUrl: './main-header-account.component.scss',
  imports: [MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, RouterLink, TranslatePipe]
})
export class MainHeaderAccountComponent {
  constructor(public service: AuthService) {
  }

  logout() {
    this.service.logout()
  }


  login() {
    this.service.login()
  }
}
