import { Component, Input, OnInit, output, signal } from '@angular/core'
import { MatIconButton } from "@angular/material/button"
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { MatToolbarModule } from '@angular/material/toolbar'
import { RouterLink } from "@angular/router"
import { AppService, HomeService } from '@mybitzer-workspace/shared'
import { Observable } from "rxjs"
import { MainMenuActionGroupComponent } from "../main-menu-action-group/main-menu-action-group.component"

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss',
  imports: [MainMenuActionGroupComponent, MatDividerModule, MatToolbarModule, MatIconModule, MatIconButton, RouterLink],
  standalone: true
})
export class MainMenuComponent implements OnInit {
  @Input() set collapsed(value: boolean) {
    this._collapsed.set(value)
  }

  get collapsed(): boolean {
    return this._collapsed()
  }

  private readonly _collapsed = signal(false)
  collapsedChange = output<boolean>()

  isHandset$: Observable<boolean>

  constructor(public service: HomeService, private readonly appService: AppService) {
    this.isHandset$ = this.appService.isMobile$
  }

  ngOnInit() {
    this.isHandset$.subscribe(h => {
      if (h) this.setCollapsed(true)
    })
  }

  toggleCollapsed() {
    this.setCollapsed(!this.collapsed)
  }

  setCollapsed(value: boolean) {
    this._collapsed.set(value)
    this.collapsedChange.emit(value)
  }
}
