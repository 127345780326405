import { Component, input, Input } from '@angular/core'

import { MatDividerModule } from '@angular/material/divider'
import { HomeService, MainMenuActionGroup } from '@mybitzer-workspace/shared'
import { MainMenuActionComponent } from "../main-menu-action/main-menu-action.component"

@Component({
  selector: 'app-main-menu-action-group',
  templateUrl: './main-menu-action-group.component.html',
  styleUrl: './main-menu-action-group.component.scss',
  imports: [MainMenuActionComponent, MatDividerModule],
})
export class MainMenuActionGroupComponent {
  @Input() data!: MainMenuActionGroup
  collapsed = input(false)

  constructor(public service: HomeService) { }
}
