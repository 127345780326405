import { Component, input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { RouterLink, RouterLinkActive } from '@angular/router'
import { MainMenuAction } from '@mybitzer-workspace/shared'
import { TranslatePipe } from '@ngx-translate/core'

@Component({
  selector: 'app-main-menu-action',
  imports: [TranslatePipe, MatIconModule, RouterLink, RouterLinkActive],
  templateUrl: './main-menu-action.component.html',
  styleUrls: ['./main-menu-action.component.scss']
})
export class MainMenuActionComponent {
  readonly data = input.required<MainMenuAction>()
  collapsed = input(false)
}
