import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay'
import { Component, signal } from '@angular/core'
import { takeUntilDestroyed } from "@angular/core/rxjs-interop"
import { MatButton } from '@angular/material/button'
import { MatDivider } from '@angular/material/divider'
import { MatPrefix } from '@angular/material/form-field'
import { MatIcon } from '@angular/material/icon'
import { MatProgressBar } from '@angular/material/progress-bar'
import { NavigationStart, Router } from "@angular/router"
import { SubscriberActivity } from '@mybitzer-workspace/backend'
import { TranslatePipe } from '@ngx-translate/core'
import { switchMap } from 'rxjs'
import { ActivityIndicatorService } from '../activity-indicator.service'
import { ActivityListComponent } from '../activity-list/activity-list.component'
import { getActivityRoute } from '../activity.const'


@Component({
  selector: 'app-activity-menu',
  templateUrl: './activity-menu.component.html',
  styleUrl: './activity-menu.component.scss',
  imports: [CdkOverlayOrigin, CdkConnectedOverlay, MatProgressBar, MatDivider, ActivityListComponent, MatButton, MatIcon, MatPrefix, TranslatePipe]
})
export class ActivityMenuComponent {

  opened = signal(false)

  constructor(protected service: ActivityIndicatorService, private readonly router: Router) {
    this.router.events.pipe(takeUntilDestroyed())
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          this.closePanel()
        }
      })
  }

  toggleMenu() {
    this.opened.set(!this.opened())
    if (this.opened()) {
      this.service.refreshUnreadActivities().subscribe()
    }
  }

  handleMarkReadAll() {
    this.service.handleMarkReadAll().subscribe()
  }

  handleMarkReadSingle(info: SubscriberActivity) {
    const { type, referenceId } = info.activity.reference
    if (info.read) {
      this.router.navigate([getActivityRoute(type, referenceId)]).then()
    } else {
      this.service.handleMarkReadSingle(info).pipe(
        switchMap(() => this.router.navigate([getActivityRoute(type, referenceId)]))
      ).subscribe()
    }


  }

  closePanel() {
    this.opened.set(false)
  }

  showAll() {
    this.closePanel()
    this.router.navigate(['portal', 'activity']).then()
  }
}
