import { Route } from '@angular/router'
import {
  canActivateAuthRole,
  PageNotFoundComponent,
  PROCESS_ADMIN,
  PROCESS_READ,
  SITE_ADMIN,
  SITE_READ, SITE_WRITE,
  SYSTEM_ADMIN,
  SYSTEM_READ,
  SYSTEM_WRITE
} from "@mybitzer-workspace/shared"

export const ROUTES: Route[] = [
  { path: '', redirectTo: 'site', pathMatch: 'full' },
  {
    path: 'process',
    title: 'Process',
    canActivate: [canActivateAuthRole],
    data: { roles: [PROCESS_READ, PROCESS_ADMIN] },
    loadChildren: () => import('../core/process/process.routes').then(m => m.ROUTES)
  },
  {
    path: 'accounting',
    title: 'BDN Accounting',
    canActivate: [canActivateAuthRole],
    data: { roles: [] },
    loadChildren: () => import('../core/accounting/accounting.routes').then(m => m.ROUTES)
  },
  {
    path: 'export',
    title: 'Data Export',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('../core/data-export/data-export.routes').then(m => m.ROUTES)
  },
  {
    path: 'info',
    title: 'Info',
    loadChildren: () => import('../core/info/info.routes').then(m => m.ROUTES)
  },
  {
    path: 'cart',
    title: 'Webshop Cart',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('../core/commerce/commerce.routes').then(m => m.ROUTES)
  },
  {
    path: 'profile',
    title: 'Profile',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('../core/profile/profile.routes').then(m => m.ROUTES)
  },
  {
    path: 'site',
    title: 'BDN Site',
    canActivate: [canActivateAuthRole],
    data: { roles: [SITE_READ, SITE_WRITE, SITE_ADMIN] },
    loadChildren: () => import('../core/site/site.routes').then(m => m.ROUTES)
  },
  {
    path: 'system',
    title: 'BDN System',
    canActivate: [canActivateAuthRole],
    data: { roles: [SYSTEM_READ, SYSTEM_WRITE, SYSTEM_ADMIN] },
    loadChildren: () => import('../core/system/system.routes').then(m => m.ROUTES)
  },
  {
    path: 'equipment',
    title: 'BDN Equipment',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('../core/equipment/equipment.routes').then(m => m.ROUTES)
  },
  {
    path: 'search',
    title: 'BDN Search',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('../core/search/search.routes').then(m => m.ROUTES)
  },
  {
    path: 'registration',
    title: 'BDN Registration',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('../core/registration/registration.routes').then(m => m.ROUTES)
  },
  {
    path: 'company',
    title: 'Company',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('../core/company/company.routes').then(m => m.ROUTES)
  },
  {
    path: 'user',
    title: 'User',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('../core/user/user.routes').then(m => m.ROUTES)
  },
  {
    path: 'invite',
    title: 'Invite',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('../core/invite/invite.routes').then(m => m.ROUTES)
  },
  {
    path: 'access',
    title: 'BDN Access',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('../core/access/access.routes').then(m => m.ROUTES)
  },
  {
    path: 'portal',
    title: 'MyBITZER Portal',
    loadChildren: () => import('../core/portal.routes').then(m => m.ROUTES)
  },
  {
    path: 'commerce',
    title: 'BDN Commerce',
    canActivate: [canActivateAuthRole],
    loadChildren: () => import('../core/commerce/commerce.routes').then(m => m.ROUTES)
  },
  { path: '**', component: PageNotFoundComponent }
]

