@for (subscriberActivity of data(); track subscriberActivity.activity.id) {
  <div class="flex items-center justify-between w-full py-5 px-4 border-l-[3px] border-[#D9D9D9]" [class.active]="subscriberActivity.activity.state.active">
    <div class="flex items-center">

      @if (subscriberActivity.activity.reference.type === 'sales-order') {
        <mat-icon class="text-lg">list_alt</mat-icon>
      } @else if (subscriberActivity.activity.reference.type === 'bdn-equipment') {
        <mat-icon class="text-lg">precision_manufacturing</mat-icon>
      } @else {
        <mat-icon class="text-lg">question_mark</mat-icon>
      }
      <div class="font-normal pl-1">
        @if (subscriberActivity.activity.content.title) {
          <h6 class="!text-base">{{ subscriberActivity.activity.content.title }}</h6>
        }
        <p class="text-sm">{{ subscriberActivity.activity.content.description }}</p>
      </div>
    </div>
    <div class="text-sm font-normal flex items-center">
      <span>{{ subscriberActivity.activity.state.created | date:'short' }}</span>
      <button mat-icon-button (click)="activityClicked.emit(subscriberActivity)">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>
  <hr>
}

