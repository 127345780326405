<div class="flex flex-row gap-5 justify-end">
  @if (service.customHeaderActions().length > 0) {
  <div class="flex flex-row gap-2 justify-end">
    @for (action of service.customHeaderActions(); track action) {
    <app-main-header-action [value]="action"></app-main-header-action>
    }
  </div>
  }
  @if (service.headerActions().length > 0) {
  <div class="flex flex-row gap-2 justify-end">
    @for (action of service.headerActions(); track action) {
    <app-main-header-action [value]="action"></app-main-header-action>
    }
  </div>
  }

</div>