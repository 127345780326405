import { bootstrapApplication } from '@angular/platform-browser'
import { ConsentService } from '@mybitzer-workspace/shared'
import { AppComponent } from './app/app.component'
import { appConfig } from './app/app.config'

bootstrapApplication(AppComponent, appConfig).then((appRef) => {
  const consentService = appRef.injector.get(ConsentService)
  consentService.initialConsent()
}).catch((err) =>
  console.error(err)
)
