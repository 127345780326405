import { Component } from '@angular/core'
import { ACTIVITY_READ, AuthService } from '@mybitzer-workspace/shared'

import { ActivityIndicatorComponent } from '../activity-indicator/activity-indicator.component'
import { ActivityMenuComponent } from '../activity-menu/activity-menu.component'

@Component({
  selector: 'app-activity-button',
  templateUrl: './activity-button.component.html',
  styleUrl: './activity-button.component.scss',
  imports: [ActivityIndicatorComponent, ActivityMenuComponent]
})
export class ActivityButtonComponent {
  constructor(protected auth: AuthService) {
  }

  protected readonly ACTIVITY_READ = ACTIVITY_READ
}
