import { DatePipe } from '@angular/common'
import { Component, input, output } from '@angular/core'
import { MatIconButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'
import { SubscriberActivity } from '@mybitzer-workspace/backend'

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss'],
  imports: [MatIcon, MatIconButton, DatePipe]
})
export class ActivityListComponent {

  data = input.required<SubscriberActivity[]>()
  activityClicked = output<SubscriberActivity>()
}
