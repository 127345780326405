import { Component, input, output } from '@angular/core'
import { MatToolbar } from '@angular/material/toolbar'

import { MatIconButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'
import { MatTooltip } from '@angular/material/tooltip'
import { HomeService } from '@mybitzer-workspace/shared'
import { TranslatePipe } from '@ngx-translate/core'
import { ActivityButtonComponent } from '../../activity/activity-button/activity-button.component'
import { MainHeaderAccountComponent } from '../main-header-account/main-header-account.component'
import { MainHeaderActionsComponent } from '../main-header-actions/main-header-actions.component'
import { MainSearchComponent } from '../main-search/main-search.component'

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrl: './main-header.component.scss',
  imports: [MatToolbar, MatIconButton, MatTooltip, MatIcon, MainSearchComponent, MainHeaderActionsComponent, ActivityButtonComponent, MainHeaderAccountComponent, TranslatePipe]
})
export class MainHeaderComponent {

  readonly isMobile = input<boolean>(false)
  readonly toggleDrawer = output<void | null>()


  constructor(public service: HomeService) {
  }

}
