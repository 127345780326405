import { NgClass } from '@angular/common'
import { Component, output, signal } from '@angular/core'
import { takeUntilDestroyed } from "@angular/core/rxjs-interop"
import { MatBadge } from '@angular/material/badge'
import { MatIcon } from '@angular/material/icon'
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from "@angular/router"
import { TranslatePipe } from '@ngx-translate/core'
import { interval, startWith } from "rxjs"
import { switchMap, tap } from "rxjs/operators"
import { ActivityIndicatorService } from '../activity-indicator.service'

@Component({
  selector: 'app-activity-indicator',
  templateUrl: './activity-indicator.component.html',
  styleUrl: './activity-indicator.component.scss',
  imports: [RouterLink, RouterLinkActive, MatIcon, NgClass, MatBadge, TranslatePipe]
})
export class ActivityIndicatorComponent {
  readonly indicatorClicked = output<MouseEvent>()
  activated = signal(false)

  constructor(protected service: ActivityIndicatorService, private router: Router) {
    interval(10000).pipe(
      startWith(0),
      switchMap(() => this.service.refreshUnreadCount()),
      takeUntilDestroyed()
    ).subscribe()

    this.router.events.pipe(
      tap(evt => {
        if (evt instanceof NavigationEnd) {
          const activeRoute = evt.url
          this.activated.set(activeRoute.endsWith('portal/activity'))
        }
      }),
      takeUntilDestroyed()
    ).subscribe()
  }

}
