import { Component, computed, effect, signal, viewChild } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav'
import { RouterOutlet } from '@angular/router'
import { AppService } from '@mybitzer-workspace/shared'
import { MainFooterComponent } from '../main-footer/main-footer.component'
import { MainHeaderComponent } from '../main-header/main-header.component'
import { MainMenuComponent } from '../main-menu/main-menu.component'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [MatSidenavContainer, MatSidenav, MainMenuComponent, MatSidenavContent, MainHeaderComponent, RouterOutlet, MainFooterComponent]
})
export class HomeComponent {
  readonly drawer = viewChild.required<MatSidenav>('drawer')

  collapsed = signal(false)
  isMobile = toSignal(this.appService.isMobile$)
  sidenavWidth = computed(() => this.setWidth(this.collapsed()))


  constructor(private appService: AppService) {
    effect(() => {
      if (this.isMobile() && this.collapsed()) {
        this.drawer().close()
      }
    })
  }

  toggleDrawer() {
    this.drawer().toggle()
    this.collapsed.set(false)
  }

  setWidth(collapsed: boolean): any {
    const collapsedWidth = this.isMobile() ? '0px' : '85px'
    return collapsed ? collapsedWidth : '300px'
  }

}
