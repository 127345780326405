@if (linkExternal) {
<a class="flex flex-col gap-2 items-center text-sm main-header-action" [href]="linkExternal.href"
  [target]="linkExternal.target">
  @if (linkExternal.icon) {
  <mat-icon>{{ linkExternal.icon }}</mat-icon>
  }
  <span>{{ linkExternal.text | translate }}</span>
</a>
}

@if (linkInternal) {
<a class="flex flex-col gap-2 items-center text-sm main-header-action" [routerLink]="linkInternal.routerLink"
  routerLinkActive="highlight">
  @if (linkInternal.icon) {
  <mat-icon>{{ linkInternal.icon }}</mat-icon>
  }
  <span>{{ linkInternal.text | translate }}</span>
</a>
}


@if (button) {
<button class="flex flex-col gap-2 items-center text-sm main-header-action" [class.highlight]="button.active"
  (click)="button.callback($event)">
  @if (button.icon) {
  @if (button.iconBadge) {
  <mat-icon class="custom-badge" [matBadge]="button.iconBadge" matBadgeSize="small" matBadgeOverlap="true"
    matBadgeColor="accent" [attr.aria-hidden]="false" [attr.aria-label]="'You have ' + button.iconBadge + ' new items'">
    {{ button.icon }}
  </mat-icon>

  } @else {
  <mat-icon>{{ button.icon }}</mat-icon>
  }
  }
  <span>{{ button.text | translate }}</span>
</button>
}