<span cdkOverlayOrigin #trigger="cdkOverlayOrigin"></span>


<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="opened()"
  (overlayOutsideClick)="closePanel()" (detach)="closePanel()" [cdkConnectedOverlayOffsetY]="37"
  [cdkConnectedOverlayOffsetX]="22" [cdkConnectedOverlayHasBackdrop]="true">
  <div class="!absolute -right-12 -top-10 mt-[2px] bg-white z-10">
    @if (service.reloading()) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    } @else {
    <div class="w-[600px] mat-elevation-z4 max-h-[80vh] overflow-y-auto">
      <div class="flex items-baseline py-5 px-4">
        <h1 class="pr-1">{{ 'activity.title' | translate }}</h1>
        <span class="h-6 px-3 text-sm rounded bg-gray-200 font-normal flex items-center">Beta</span>
      </div>
      <mat-divider></mat-divider>
      <app-activity-list [data]="service.unreadActivities()"
        (activityClicked)="handleMarkReadSingle($event)"></app-activity-list>
      <div class="flex justify-center gap-3 py-5 px-4">
        <button mat-flat-button color="accent" class="mark-all-btn" (click)="handleMarkReadAll()">
          <mat-icon>done_all</mat-icon>
          {{ 'activity.table.markAllAsRead' | translate }}
        </button>
        <button mat-flat-button color="accent" (click)="showAll()" (keyup.enter)="showAll()" aria-label="Show all">
          <mat-icon matIconPrefix>chevron_right</mat-icon>
          {{ 'activity.table.showAll' | translate }}
        </button>
      </div>
    </div>
    }
  </div>
</ng-template>