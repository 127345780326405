import { Component } from '@angular/core'
import { MatAnchor, MatButton } from '@angular/material/button'
import { HomeService } from '@mybitzer-workspace/shared'
import { TranslatePipe } from '@ngx-translate/core'

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrl: './main-footer.component.scss',
  imports: [MatAnchor, MatButton, TranslatePipe]
})
export class MainFooterComponent {

  constructor(private service: HomeService) {
  }

  openCookieSettings() {
    this.service.openCookieSettings()
  }
}
