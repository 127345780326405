<a class="flex flex-col h-10 justify-center p-3 rounded-md main-menu-action" routerLink="{{ data().url }}"
  routerLinkActive="highlight">


  <div class="flex flex-row gap-3 items-center justify-center">
    <mat-icon>{{ data().icon }}</mat-icon>
    @if (!collapsed()) {
    <div class="flex flex-row gap-2 justify-between content-start flex-auto">
      <div>{{ data().name | translate }}</div>
      @if (data().status) {
      <span class="px-3 text-sm rounded" [class]="data().status!.color">{{
        data().status!.text | translate
        }}</span>
      }
    </div>
    }
  </div>


</a>