import { Component } from '@angular/core'
import { HomeService } from '@mybitzer-workspace/shared'
import { MainHeaderActionComponent } from '../main-header-action/main-header-action.component'

@Component({
  selector: 'app-main-header-actions',
  templateUrl: './main-header-actions.component.html',
  styleUrl: './main-header-actions.component.scss',
  imports: [MainHeaderActionComponent]
})
export class MainHeaderActionsComponent {



  constructor(public service: HomeService) {
  }

}
