const basePath = 'assets/img/icons/'

export const ICON_CATEGORIES = {
  salesOrder: [
    { name: 'close', path: `${basePath}close.svg` },
    { name: 'delivered', path: `${basePath}delivered.svg` },
    { name: 'delivering', path: `${basePath}delivering.svg` },
    { name: 'in_progress', path: `${basePath}in_progress.svg` },
    { name: 'partially_shipped', path: `${basePath}partially_shipped.svg` },
    { name: 'unknown', path: `${basePath}unknown.svg` }
  ],
  documents: [
    { name: 'download', path: `${basePath}download.svg` },
    { name: 'extern_link', path: `${basePath}extern_link.svg` },
    { name: 'extern_link_white', path: `${basePath}extern_link_white.svg` },
    { name: 'html', path: `${basePath}html.svg` },
    { name: 'pdf', path: `${basePath}pdf.svg` },
    { name: 'video', path: `${basePath}video.svg` },
    { name: 'zip', path: `${basePath}zip.svg` }
  ],
  product: [
    { name: 'technical_data', path: `${basePath}technical_data.svg` },
    { name: 'ix_configuration', path: `${basePath}ix_configuration.svg` },
    { name: 'gears', path: `${basePath}gears.svg` },
    { name: 'bz_documents', path: `${basePath}bz_documents.svg` },
  ]
}
