<div class="flex flex-row gap-2 justify-center h-full">
  @if (service.unreadCount() <= 0) { <a routerLink="/portal/activity"
    class="flex flex-col gap-2 justify-center items-center text-sm main-header-action min-h-14"
    routerLinkActive="highlight">
    <mat-icon>notifications</mat-icon>
    <span>{{ 'action.activity' | translate }}</span>
    </a>
    } @else {
    <button (click)="indicatorClicked.emit($event)"
      class="flex flex-row gap-2 justify-center items-center text-sm main-header-action min-h-14"
      [ngClass]="activated() ? 'highlight' : ''">
      <mat-icon matBadge="{{service.unreadCount()}}" matBadgeSize="small"
        [matBadgeColor]="activated() ? 'warn' : 'accent'" [attr.aria-hidden]="'false'"
        [attr.aria-label]="'You have ' + service.unreadCount() + ' unread notifications'">
        notifications
      </mat-icon>

    </button>
    }
</div>