import { Injectable, signal } from '@angular/core'
import { ActivityService, SubscriberActivity } from '@mybitzer-workspace/backend'
import { AppService, Page } from '@mybitzer-workspace/shared'
import { Observable, of, switchMap, tap } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ActivityIndicatorService {

  unreadCount = signal<number>(0)
  unreadActivities = signal<SubscriberActivity[]>([])
  reloading = signal(false)

  constructor(private service: ActivityService, private app: AppService) {
  }


  refreshUnreadCount() {
    return this.service.getUnreadCount().pipe(
      tap(value => this.unreadCount.set(value))
    )
  }

  refreshUnreadActivities(): Observable<Page<SubscriberActivity>> {
    this.reloading.set(true)
    return this.service.getUnread(0, 10)
      .pipe(
        tap({
          next: page => this.handleData(page.content),
          error: err => this.handleError(err)
        }),
      )
  }

  handleMarkReadAll(): Observable<Page<SubscriberActivity>> {
    this.reloading.set(true)
    return this.service.markAllActivityRead().pipe(
      switchMap(() => this.refreshUnreadCount()),
      switchMap(() => this.refreshUnreadActivities()),
    )
  }

  handleMarkReadSingle(info: SubscriberActivity): Observable<Page<SubscriberActivity>> {
    if (info.read) return of()
    this.reloading.set(true)
    return this.service.markAsRead(info.activity.id).pipe(
      switchMap(() => this.refreshUnreadCount()),
      switchMap(() => this.refreshUnreadActivities())
    )
  }

  private handleData(value: SubscriberActivity[]) {
    this.unreadActivities.set(value)
    this.reloading.set(false)
  }

  private handleError(err: any) {
    this.unreadActivities.set([])
    this.reloading.set(false)
    if (err) this.app.handleError(err)
  }

}
