<div class="overflow-hidden main-menu">
  <mat-toolbar color="primary">
    @if (!collapsed) {
    <div class="flex flex-row gap-2 justify-center flex-auto cursor-pointer" routerLink="/portal/landingpage">
      <img src="assets/img/logo.png" alt="BITZER logo" class="max-w-32" />
    </div>
    }
    <button (click)="toggleCollapsed()" aria-label="Expand/Collapse button" mat-icon-button>
      @if (collapsed) {
      <mat-icon>keyboard_double_arrow_right</mat-icon>
      } @else {
      <mat-icon>keyboard_double_arrow_left</mat-icon>
      }
    </button>
  </mat-toolbar>
  <div class="flex flex-col gap-4 justify-center content-center flex-auto pt-3">
    @for (group of service.menuActions(); track group) {
    <app-main-menu-action-group [data]="group" class="px-3" [collapsed]="collapsed"></app-main-menu-action-group>
    }
    <div class="flex-auto"></div>
    <mat-divider></mat-divider>
    <div class="px-3 fixed bottom-0 pb-3 text-gray-400">
      @if (!collapsed) {
      Version
      } {{ service.version }}
    </div>
  </div>
</div>